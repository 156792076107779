import { defineAsyncComponent } from 'vue';

import $ from 'jquery';
import Vue3TouchEvents from 'vue3-touch-events';

import '@sass/home-nal/app.scss';
import '../_app.js';
import './lang.js';

import { loadCategories } from '../core/categories.js';
import EzekiaLocalStorage from '../core/EzekiaLocalStorage.js';
import store from './store';
import App from './vue-components/application.vue';
import router from './router.js';

window.packageBuild = 'home-nal';

window.$ = $;
window.jQuery = $;

loadCategories();

window.token = document.head.querySelector('meta[name="csrf-token"]').content;

window.typeahead = require('corejs-typeahead');

window.mainSite = true;

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (window.token) {
        jqXHR.setRequestHeader('X-CSRF-Token', window.token);
    }
    jqXHR.setRequestHeader('REQUEST_HASH', window.location.hash);
});

// Location
let setCountry = (country, resetCache = true) => {
    window.setVueObject(window.nal, 'country', country);
    window.setCountry(country);
    if (resetCache) {
        EzekiaLocalStorage.set('country', country);
    }
};
(() => {
    if (EzekiaLocalStorage.get('country')) {
        setCountry(EzekiaLocalStorage.get('country'), false);
        return;
    }
    let ipInfoUrl = 'https://ipinfo.io/json';
    const token = import.meta.env.VITE_IPINFO_ACCESS_TOKEN;
    if (!!token && token !== 'undefined') {
        ipInfoUrl += `?token=${token}`;
    }
    $.ajax(ipInfoUrl)
        .done(data => setCountry(data?.country ?? 'GB'))
        .fail(() => setCountry('GB'));
})();

if (window.nal.user) {
    store.commit('user/SET_USER', _.cloneDeep(window.nal.user));
}

window.nal.store = store;

import('../vue3factory.js').then(({ createMyApp }) => {
    const homeNalApp = createMyApp(App);

    // Register usings
    homeNalApp.use(Vue3TouchEvents);
    homeNalApp.use(store);
    homeNalApp.use(router);

    // Register Components
    const components = {
    // Alerts - passed as prop in notify()
        PasswordErrorsAlert: import('@js/vue-components/auth/password-errors-alert.vue'),
    };

    for (const [componentName, componentImport] of Object.entries(components)) {
        homeNalApp.component(componentName, defineAsyncComponent(() => componentImport));
    }

    window.app = homeNalApp.mount('#app');
});