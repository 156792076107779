import { createRouter, createWebHashHistory } from 'vue-router';

import Analytics from '@js/core/cookies/analytics.js';

import { userStore } from './user/userStore.js';
import { loadCategories } from '../core/categories.js';

const loadCategoriesOnce = _.once(loadCategories);
const Register = () => import('./vue-components/registration/page-register.vue');
const NotFound = () => import('./vue-components/common/not-found.vue');
const Registration = () => import('./vue-components/registration/page-registration.vue');

const pageOrder = [
    'cv', 'current', 'position', 'aspirations',
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/home',
            component: Register,
            alias: '/',
            name: 'home',
        },
        {
            path: '/contact',
            component: () => import('./vue-components/contact/page-contact.vue'),
            name: 'contact',
        },
        {
            path: '/partners',
            component: () => import('./vue-components/partners/page-partners.vue'),
            name: 'partners',
        },
        {
            path: '/search-firms',
            component: () => import('./vue-components/search-firms/page-search-firms.vue'),
            name: 'search-firms',
        },
        {
            path: '/login',
            component: Register,
            name: 'login',
            meta: {
                guest: true,
            },
            beforeEnter(to, from, next) {
                if (userStore.loggedIn() && !userStore.finishedRegistration()) {
                    let page = userStore.user.page.includes('register') ? 'cv' : userStore.user.page;
                    let routeName = `register.${page || 'cv'}`;
                    return next({
                        name: routeName,
                    });
                }
                if (window.awaiting2FA) {
                    return next({
                        name: 'token',
                    });
                }
                if (window.browserNeedsVerification) {
                    return next({
                        name: 'new-browser-pin',
                    });
                }
                if (window.awaitingActivation) {
                    return next({
                        name: 'activate',
                    });
                }
                return next();
            },
        },
        {
            path: '/reset',
            component: Register,
            name: 'reset',
            meta: {
                guest: true,
            },
        },
        {
            path: '/invalid-password',
            component: Register,
            name: 'invalid',
            meta: {
                guest: true,
            },
        },
        {
            path: '/login/token',
            component: Register,
            name: 'token',
            beforeEnter(to, from, next) {
                if (!window.awaiting2FA) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/login/new-browser-pin',
            component: Register,
            name: 'new-browser-pin',
            beforeEnter(to, from, next) {
                if (!window.browserNeedsVerification) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/login/activate',
            component: Register,
            name: 'activate',
            beforeEnter(to, from, next) {
                if (!window.awaitingActivation) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/register',
            component: Register,
            name: 'register',
            beforeEnter(to, from, next) {
                if (userStore.loggedIn() && !userStore.finishedRegistration()) {
                    let page = userStore.user.page.includes('register') ? 'cv' : userStore.user.page;
                    let routeName = `register.${page || 'cv'}`;
                    return next({
                        name: routeName,
                    });
                }
                if (userStore.loggedIn()) {
                    return window.location.href = '/';
                }
                if (window.awaiting2FA) {
                    return next({
                        name: 'token',
                    });
                } else if (window.browserNeedsVerification) {
                    return next({
                        name: 'new-browser-pin',
                    });
                } else if (window.awaitingActivation) {
                    return next({
                        name: 'activate',
                    });
                }
                return next();
            },
            meta: {
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/recover',
            component: Register,
            name: 'recover',
            props(route) {
                return {
                    token: route.query.token,
                };
            },
        },
        {
            path: '/terms/:tab?',
            component: () => import('./vue-components/terms/terms-wrapper.vue'),
            name: 'terms',
            props: true,
        },
        {
            path: '/register/cv',
            component: Registration,
            name: 'register.cv',
            meta: {
                requiresAuth: 'login',
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/current',
            component: Registration,
            name: 'register.current',
            meta: {
                requiresAuth: 'login',
                requiresCategories: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/position',
            component: Registration,
            name: 'register.position',
            meta: {
                requiresAuth: 'login',
                requiresCategories: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/aspirations',
            component: Registration,
            name: 'register.aspirations',
            meta: {
                requiresAuth: 'login',
                requiresCategories: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/:page',
            redirect: '/register/cv',
        },
        {
            path: '/not-found',
            component: NotFound,
            name: 'not-found',
        },
        {
            path: '/:pathMatch(.*)*',
            component: NotFound,
            name: 'catch-all',
            beforeEnter(to, from, next) {
                return next({
                    name: 'login',
                    query: { redirect: to.fullPath },
                });
            },
        },
    ],
    linkActiveClass: 'active',

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
        if (to.meta.noScroll) {
            return false;
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const route = to.matched.find(record => record.meta.requiresAuth);
    if (route && !userStore.loggedIn()) {
        return next({
            name: route.meta.requiresAuth,
            query: { redirect: to.fullPath },
        });
    }
    if (to.matched.some(record => record.meta.guest)) {
        if (userStore.loggedIn()) {
            return next({
                name: 'register',
            });
        }
    }
    if (to.matched.some(record => record.meta.requiresCategories)) {
        loadCategoriesOnce();
    }
    if (to.matched.some(record => record.meta.redirectsToCurrentPage)) {
        if (userStore.loggedIn() && !userStore.finishedRegistration()) {
            let userPage = (userStore.user.page.includes('register') ? 'cv' : userStore.user.page);
            let currentPage = to.name.replace('register.', '');
            if (pageOrder.indexOf(currentPage) > pageOrder.indexOf(userPage)) {
                return next({
                    name: 'register.' + userPage,
                });
            }
        }
        if (userStore.finishedRegistration()) {
            return next({
                name: 'home',
            });
        }
    }
    next();
});

Analytics.setPage({ path: router.currentRoute.path });

router.afterEach((to) => {
    Analytics.setPage({ path: to.path });
});

export default router;